<template>
  <footer class="mt-10 rounded-t-2xl bg-dnd_light_grey lg:mt-20">
    <div class="customContainer flex flex-col gap-y-2">
      <div class="flex flex-row justify-between px-4 py-4 xl:px-0">
        <div>
          <img
            class="logoSize"
            src="assets/icons/pvLogo.svg"
            alt="Простой вопрос"
            size="70"
            width="70"
            height="23"
          />
        </div>
        <div class="flex flex-col items-end gap-y-0.5">
          <div
            class="font-manrope text-[10px] font-semibold text-dnd_dark_grey no-underline xl:text-sm"
          >
            Телефон (звонок по РФ бесплатный)
          </div>
          <a
            href="tel:88007005965"
            class="mt-1 font-manrope text-base font-semibold !no-underline hover:opacity-30 xl:text-xl"
          >
            8 (800) 700-59-65
          </a>
        </div>
      </div>
      <div
        class="relative flex w-full flex-col px-4 font-manrope text-[10px] lg:container xl:items-center xl:justify-center xl:px-0 xl:text-sm"
      >
        <div
          class="xl:item-start xl: flex-row gap-x-10 gap-y-4 font-semibold xl:flex xl:justify-between"
        >
          <div class="flex flex-col xl:w-full">
            <span class="font-semibold">
              Общество с ограниченной ответственностью Микрокредитная компания «АДЕЛАИДА» (ООО МКК
              «АДЕЛАИДА»).
            </span>
            <p>
              Регистрационный номер записи в в государственном реестре микрофинансовых организаций:
              № 2303140009995
            </p>
            <p>Дата внесения в государственный реестр микрофинансовых организаций 17.10.2023</p>
            <p>ОГРН 1237800087040 ИНН/КПП 7838115690 / 783801001</p>
            <NuxtLink
              class="hover:opacity-30"
              to="/informatsiya-o-strukture-i-sostave-uchastnikov-mikrofinansovoi-organizatsii-ooo-mkk-adelaide/"
            >
              Информация о структуре и составе участников микрофинансовой организации ООО МКК
              «Аделаида»
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/personalnii-sostav-organov-upravleniya-mfo/"
            >
              Персональный состав органов управления МФО
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/informatsiya-ob-usloviyakh-ooo-mkk-adelaida/"
            >
              Информация об условиях предоставления, использования и возврата потребительского займа
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/pravila-predostavleniya-mikrozaimov-ooo-mkk-adelaida/"
            >
              Правила предоставления микрозаймов
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/ustav-ooo-mkk-adelaide/"
            >
              Устав
            </NuxtLink>
            <p>
              ООО МКК «Аделаида» является членом Саморегулируемой организации «МиР» с 07.12.2023
            </p>
          </div>
          <div class="flex flex-col xl:w-full">
            <NuxtLink
              class="hover:opacity-30"
              href="/poryadok-osparivaniya-svedenii-soderzhashchikhsya-v-kreditnoi-istorii/"
            >
              Порядок оспаривания сведений, содержащихся в кредитной истории
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/mesto-neposredstvennogo-vedeniya-deyatelnosti-mfo/"
            >
              Место непосредственного ведения деятельности МФО
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/adres-mesta-nakhozhdeniya-mfo/"
            >
              Адрес места нахождения МФО и обособленных подразделений
            </NuxtLink>
            <NuxtLink
              class="hover:opacity-30"
              href="/rezhim-raboti-mfo-i-yee-obosoblennikh-podrazdelenii/"
            >
              Режим работы МФО и ее обособленных подразделений
            </NuxtLink>
            <a
              target="_blank"
              class="hover:opacity-30"
              :href="`https://api.prostoyvopros.ru/v1/file/1700770763196_.pdf`"
            >
              Базовый стандарт по управлению рисками микрофинансовых организаций
            </a>
            <a
              target="_blank"
              class="hover:opacity-30"
              :href="`https://api.prostoyvopros.ru/v1/file/1700770819066_.pdf`"
            >
              Базовый стандарт защиты прав и интересов физических и юридических лиц — получателей
              финансовых услуг, оказываемых членами саморегулируемых организаций в сфере финансового
              рынка, объединяющих микрофинансовые организации
            </a>
            <a
              target="_blank"
              class="hover:opacity-30"
              :href="`https://api.prostoyvopros.ru/v1/file/1700770861208_.pdf`"
            >
              Базовый стандарт совершения микрофинансовой организацией операций на финансовом рынке
            </a>

            <a
              target="_blank"
              class="hover:opacity-30"
              :href="`https://api.prostoyvopros.ru/v1/file/1700770899094_.pdf`"
            >
              Информация о дополнительных мерах поддержки военнослужащих.
            </a>
          </div>
        </div>

        <div
          class="grid gap-y-2 pt-2 font-manrope xl:mt-10 xl:flex xl:flex-row xl:gap-10 xl:font-bold"
        >
          <div class="flex flex-col font-bold xl:w-1/3">
            <p>СРО «МиР»</p>
            <a
              target="_blank"
              class="hover:opacity-30"
              href="https://npmir.ru/"
            >
              https://npmir.ru/
            </a>
            <p>107078, г. Москва Орликов переулок, д.5, стр.1, этаж 2, пом.11</p>
            <p class="pb-2 pt-4 lg:pb-0">
              Общество с ограниченной ответственностью Микрокредитная компания «КСМ» (ООО МКК
              «КСМ»). Регистрационный номер записи в в государственном реестре микрофинансовых
              организаций: №2403140010062 Дата внесения в государственный реестр микрофинансовых
              организаций 19.06.2024 ИНН 7840091609, ОГРН 1207800019767 ООО МКК «КСМ» является
              членом Саморегулируемой организации Союз "МИКРОФИНАНСОВЫЙ АЛЬЯНС" с 12.07.2024
            </p>
          </div>
          <div class="flex flex-col font-bold xl:w-1/3">
            <div>
              <p>Банк России</p>
              <a
                target="_blank"
                class="hover:opacity-30"
                href="https://www.cbr.ru/"
              >
                https://www.cbr.ru/
              </a>
            </div>
            <div>
              <p>Интернет-приемная Банка России</p>
              <a
                target="_blank"
                class="hover:opacity-30"
                href="https://www.cbr.ru/Reception/"
              >
                https://www.cbr.ru/Reception/
              </a>
            </div>
            <div>
              <p>Реестры субъектов рынка микрофинансирования</p>
              <a
                target="_blank"
                class="hover:opacity-30"
                href="https://www.cbr.ru/microfinance/registry/"
              >
                https://www.cbr.ru/microfinance/registry/
              </a>
            </div>
          </div>
          <div class="flex flex-col justify-between font-bold xl:w-1/3">
            <p>
              Потребитель финансовых услуг вправе направить обращение финансовому уполномоченному в
              соответствии со статьями 15-19 Федерального закона от 4 июня 2018 года N 123-ФЗ "Об
              уполномоченном по правам потребителей финансовых услуг".
            </p>
            <div>
              <a
                target="_blank"
                class="hover:opacity-30"
                href="https://finombudsman.ru/"
              >
                https://finombudsman.ru/
              </a>
              <p>119017, г. Москва, Старомонетный пер., дом 3</p>
              <a
                target="_blank"
                class="hover:opacity-30"
                href="tel:88002000010"
              >
                8 (800) 200-00-10
              </a>
            </div>
          </div>
        </div>

        <hr class="mt-8 hidden w-full items-center bg-white opacity-40 xl:flex" />

        <div
          class="flex w-full flex-col items-center justify-around pb-6 pt-4 lg:flex-row xl:pb-16 xl:pt-3"
        >
          <div class="flex w-full justify-start xl:justify-start">
            <div class="font-bold lg:justify-start">Диапазон ПСК 0-292% годовых</div>
          </div>
          <div class="flex w-full items-center justify-start gap-4 lg:justify-end">
            <p class="whitespace-nowrap font-manrope text-[10px] font-bold xl:text-sm">
              Коммерческое обозначение:
            </p>
            <img
              class="logoSize"
              src="assets/icons/pvLogo.svg"
              alt="Простой вопрос"
              sizes="70"
              width="70"
              height="23"
            />
          </div>
        </div>
        <hr class="h-px w-full bg-white opacity-40" />
      </div>
    </div>
  </footer>
</template>

<style scoped>
a {
  @apply underline decoration-[rgba(0,0,0,0.2)] underline-offset-1 transition-opacity duration-300 hover:opacity-30;
}

.logoSize {
  @apply pointer-events-none w-[120px] select-none lg:w-[168px];
}
</style>
